import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import md5 from 'md5';
import moment from 'moment';
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            tabselect: 1,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: false,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            withdrawtab: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            counter: 300,
            userrecomend: "",
            historylist: [],
            useradvice: [],
            cashoutlist: [],
            tblnextrank: [],
            tblrankall: [],
            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
        }
    }
    async calllisthistory(userid) {
        await instance.post("/api/v1/userwithdrawlist", {
            userid: userid,
            Cat_ID: 0,
            System: Systems,
            page: 1,
            type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                //  //console.log(datas.withdrawlist);
                this.setState({
                    historylist: datas.withdrawlist
                });

            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    handleClick = async (e, price) => {
        if (price <= this.state.balance) {
            this.setState({
                withdrawamount: price
            });
        }
        else {
            await Swal.fire({
                title: 'Warning!',
                text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }
    }
    checktab(e, index) {
        //console.log(index == 2 &&  this.state.qrcode != null);
        if (index == 2 && this.state.tabselectsec == 1) {

        }
        else {
            if (index == 2 && this.state.qrcode != null) {
                this.setState({
                    tabselect: 0,
                    tabselectsec: 1,
                });
            }
            else {
                this.setState({
                    tabselect: index,
                    tabselectsec: 0,
                });
            }
        }
    }
    onChangexdep = event => {
        ////console.log(event.target.value);
        if (event.target !== undefined) {

            this.setState({ depositamount: event.target.value });
        }
    };
    submitwithdraw = async (e) => {
        //////console.log(this.state.withdrawamount, this.state.balance);
        if (parseFloat(this.state.withdrawamount) <= parseFloat(this.state.balance)) {
            if (parseFloat(this.state.withdrawamount) >= 10) {
                var token = localStorage.getItem('auth_token');
                if (token != null) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    await instance.post("/api/v1/userwithdraw", {
                        User_id: Username,
                        System: Systems,
                        amount: this.state.withdrawamount
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            await Swal.fire({
                                title: 'Success!',
                                text: 'รายการถอนเรียบร้อย',
                                icon: 'Success',
                                confirmButtonText: 'ตกลง'
                            })

                            this.setState({
                                withdrawtab: 1,
                            });
                        }
                        else if (res.data.status === 203) {
                            await Swal.fire({
                                title: 'Error!',
                                text: 'ท่านติด Turnover อีก ' + res.data.amountturnover + " บาท",
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    });
                }
                else {
                    await Swal.fire({
                        title: 'error!',
                        text: 'รายการถอนมีปัญหา โปรดติดต่อพนักงาน',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    })
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'ขั้นต่ำในการถอน 50 บาท',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else {
            await Swal.fire({
                title: 'Warning!',
                text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
        }

    }

    onChange = async (event) => {
        console.log(event);
        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            if (nam === "withdrawamount") {
                if (this.state.maxwithdrawstatus === 1) {
                    this.setState({ [nam]: this.state.balance });
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'บังคับถอนยอดทั้งหมด',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                }
                else if (Number(val) <= Number(this.state.balance)) {
                    this.setState({ [nam]: val });
                }
                else {
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'ยอดถอนของท่านไม่เพียงพอในการถอน',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                }
            } else {
                this.setState({ [nam]: val });
            }

        }
    };
    handleClickdeposit = async (e, price) => {
        this.setState({
            depositamount: price

        });
    }

    confirmdeposit = async (e, num) => {
        console.log(493, num)
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        if (token != null) {
            //   await instance.post("/api/v1/getredeempromotion", {
            //     userid: Username,
            //     System: Systems,
            //   }, {
            //     headers: { Authorization: `Bearer ${token}` }
            //   }).then(async (res) => {
            //     if (res.data.status === 200) {
            //       this.setState({
            //         proderedeem: res.data.message.tblpromotionredeems,
            //       });

            //     }
            //   }).catch(async (err) => {
            //   });
        }

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                if (num === 0) {
                    if (token != null) {
                        Username = decoded.message.playerid;
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture,
                                counter: 300,
                                tabselectsec: 1,
                                tabselect: 0
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }

        else if (num === 5) {
            document.getElementById("truemonney").click()
            //deposittruemoneyModal
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.calllisthistory(Username);
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    async componentDidMount() {
        // this.checklogin();
        setInterval(() =>
            this.setState({
                counter: this.state.counter - 1
            })
            , 1000);
        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
            if (token != null) {
                var decoded = jwt_decode(token);
                let Username = decoded.message.playerid;
                instance.post("/api/v1/getqrcode", {
                    username: Username,
                    System: Systems,
                    amount: 50
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then(async (res) => {
                    //////console.log(res.data.picture)
                    this.setState({
                        mainacc: res.data.mainacc,
                    });
                });
            }
        }
        catch (error) {
            //////console.log();
        }
        try {

            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {


                    const datas = res.data.message;
                    // console.log( datas[7].value)
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    this.setState({
                        maxwithdrawstatus: maxwithdrawstatus,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (ex) {
            console.log(ex);
        }
    }

    render() {
        const { loginstate, userid, username, point, accnumber, firstname, mainacc, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        var accnumberx;
        if (accnumber != null) {
            accnumberx = accnumber[0];
        }
        return (
            <>

                <div>
                    <br />
                    <br />
                    <br />
                </div>

                {/**/}
                <div className="padding-router-view">
                    <div className="container p-0 x-margin-top-v3">
                        <div className="row m-0 border-shadow-title">
                            <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                                <div className="text-center bg-title-web">
                                    <div>
                                        <div className="text-title text-white-v1 text-center">
                                            <div className="d-flex justify-content-between">
                                                <div className="text-white-v1-deposit" href='/'>
                                                    <a role="button" href='/'>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={25}
                                                            height={25}
                                                            fill="currentColor"
                                                            className="bi bi-arrow-left-circle text-color-back"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div className="text-white-v1-deposit">
                                                    <h5><span id='menu161'>ประวัติการทำรายการ</span></h5>
                                                </div>
                                                <div>{/**/}</div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-0 container animated animatedFadeInUp fadeInUp text-center">
                                    <div className="row m-0 text-center">
                                        <div className="col-12 p-2 text-center">
                                            <div
                                                data-v-02098bde=""
                                                data-v-28a2bed6=""
                                                className="tourneys__tabs row text-center"
                                            >
                                                <button
                                                    data-v-47c7b004=""
                                                    data-v-28a2bed6=""
                                                    type="button"
                                                    className="active button-tab button-tab_md button-tab_blue pl-3 pr-3 col-4 text-center"
                                                >
                                                    <span
                                                        data-v-47c7b004=""
                                                        data-v-28a2bed6=""
                                                        className="pl-4-pr-4"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={16}
                                                            height={16}
                                                            fill="currentColor"
                                                            className="bi bi-wallet2"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                                                        </svg>{" "}
                                                        <span id='menu162'>ทั้งหมด</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div
                                                data-v-ab1d19ba=""
                                                className="auction-list animated animatedFadeInUp fadeInUp text-white-v1"
                                            >
                                                <div style={{ display: (this.state.historylist.length === 0) ? "" : "none" }} >
                                                    <div>
                                                        <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                            <b>
                                                                <img
                                                                    className="load-history"
                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                                    alt=""
                                                                />
                                                                <div className="not-history font-history-menu-text p-2">
                                                                    <small>
                                                                        <b> <span id='menu163'>ไม่พบรายการ...</span></b>
                                                                    </small>
                                                                </div>
                                                            </b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ display: (this.state.historylist.length === 0) ? "none" : "" }}
                                                    data-v-ab1d19ba=""
                                                    className="auction-list animated animatedFadeInUp fadeInUp text-white-v1"
                                                >
                                                    <div data-v-0bcfa847="" data-v-ab1d19ba="">
                                                        <div data-v-0bcfa847="" className="list__head">
                                                            <span data-v-0bcfa847="">  <span id='menu209'>ประวัติทำรายการ</span></span>
                                                            <span data-v-0bcfa847="">  <span id='menu208'>เวลา</span> </span>
                                                            <span data-v-0bcfa847="">  <span id='menu207'>จำนวนเงิน</span> </span>
                                                        </div>
                                                        <div>
                                                            {this.state.historylist.map((listitem, i) => (
                                                                <>
                                                                    <div data-v-0bcfa847="" className="list__inner">
                                                                        <div>
                                                                            <div data-v-0bcfa847="" className="list-item">
                                                                                <div data-v-0bcfa847="" className="list-item__user">
                                                                                    <div data-v-0bcfa847="" className="item-user">
                                                                                        <div
                                                                                            data-v-b41a49a6=""
                                                                                            data-v-0bcfa847=""
                                                                                            className="user item-user__userpick user--clickable"
                                                                                        >
                                                                                            <div data-v-b41a49a6="" className="user__wrap" />
                                                                                        </div>
                                                                                        <span
                                                                                            data-v-0bcfa847=""
                                                                                            className={(listitem.type === "BN") ? "*โบนัส" : (listitem.type === "DE") ? "item-user__betsize text-success" : "item-user__betsize text-danger"}                                                                                              >
                                                                                            <h5 className="m-0 font-list-history">{(listitem.type === "BN") ? "*โบนัส" : (listitem.type === "DE") ? "ฝากเงิน" : "ถอนเงิน"}</h5>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div data-v-0bcfa847="" className="list-item__user">
                                                                                    <div data-v-0bcfa847="" className="item-user">
                                                                                        <div
                                                                                            data-v-b41a49a6=""
                                                                                            data-v-0bcfa847=""
                                                                                            className="user item-user__userpick user--clickable"
                                                                                        >
                                                                                            <div data-v-b41a49a6="" className="user__wrap" />
                                                                                        </div>
                                                                                        <span data-v-0bcfa847="" className="item-user__name">
                                                                                            {moment(listitem.createdate).format('DD/MM/YY') + " - " + moment(listitem.createdate).format('HH:mm') + " น."}
                                                                                        </span>
                                                                                        <span
                                                                                            data-v-0bcfa847=""
                                                                                            className="item-user__betsize text-success"
                                                                                        >
                                                                                            <div style={{ display: (listitem.status === "2") ? "" : "none" }}>
                                                                                                <span className="-state-text">สถานะ : </span>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise text-warning" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path><path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path></svg>
                                                                                                {" "} <span className="-state-title text-warning">กำลังดำเนินการ</span>
                                                                                            </div>
                                                                                            <div style={{ display: (listitem.status === "0") ? "" : "none" }}>
                                                                                                <span className="-state-text">สถานะ : </span>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-counterclockwise text-warning" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"></path><path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path></svg>
                                                                                                {" "} <span className="-state-title text-warning">รอดำเนินการ</span>
                                                                                            </div>
                                                                                            <div style={{ display: (listitem.status === "4") ? "" : "none" }}>
                                                                                                <span className="-state-text">สถานะ : </span>
                                                                                                <img
                                                                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                                    className="-ic"
                                                                                                    width="16" height="16"
                                                                                                    alt=""
                                                                                                />
                                                                                                {" "}
                                                                                                <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                                                                            </div>
                                                                                            <div style={{ display: (listitem.status === "5") ? "" : "none" }}>
                                                                                                <span className="-state-text">สถานะ : </span>
                                                                                                <img
                                                                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                                    className="-ic"
                                                                                                    width="16" height="16"
                                                                                                    alt=""
                                                                                                />
                                                                                                {" "}
                                                                                                <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                                                                            </div>
                                                                                            <div style={{ display: (listitem.status === "1") ? "" : "none" }}>
                                                                                                <span className="-state-text">สถานะ : </span>
                                                                                                <img
                                                                                                    src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                                                    className="-ic"
                                                                                                    width="16" height="16"
                                                                                                    alt=""
                                                                                                />
                                                                                                {" "}
                                                                                                <span className="-state-title">สำเร็จ</span>
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div data-v-0bcfa847="" className="list-item__expected">
                                                                                    <div data-v-0bcfa847="" className="currency">
                                                                                        <span data-v-0bcfa847="">
                                                                                            <span
                                                                                                className={(listitem.type === "DE") ? "font-digits text-success" : "font-digits text-danger"}
                                                                                                style={{ color: "rgb(255, 255, 255)", fontSize: 22 }}
                                                                                            >
                                                                                                {listitem.amount}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div data-v-0bcfa847="" className="list__inner">
                                                                                <div>
                                                                                    <div data-v-0bcfa847="" className="list-item">
                                                                                        <div data-v-0bcfa847="" className="list-item__user">
                                                                                            <div data-v-0bcfa847="" className="item-user">
                                                                                                <div
                                                                                                    data-v-b41a49a6=""
                                                                                                    data-v-0bcfa847=""
                                                                                                    className="user item-user__userpick user--clickable"
                                                                                                >
                                                                                                    <div data-v-b41a49a6="" className="user__wrap" />
                                                                                                </div>
                                                                                                <span
                                                                                                    data-v-0bcfa847=""
                                                                                                    className="item-user__betsize text-success"
                                                                                                >
                                                                                                    <h5 className="m-0 font-list-history">ฝากเงิน</h5>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-v-0bcfa847="" className="list-item__user">
                                                                                            <div data-v-0bcfa847="" className="item-user">
                                                                                                <div
                                                                                                    data-v-b41a49a6=""
                                                                                                    data-v-0bcfa847=""
                                                                                                    className="user item-user__userpick user--clickable"
                                                                                                >
                                                                                                    <div data-v-b41a49a6="" className="user__wrap" />
                                                                                                </div>
                                                                                                <span data-v-0bcfa847="" className="item-user__name">
                                                                                                    2024-05-06 21:31:45
                                                                                                </span>
                                                                                                <span
                                                                                                    data-v-0bcfa847=""
                                                                                                    className="item-user__betsize text-success"
                                                                                                >
                                                                                                    สำเร็จ
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div data-v-0bcfa847="" className="list-item__expected">
                                                                                            <div data-v-0bcfa847="" className="currency">
                                                                                                <span data-v-0bcfa847="">
                                                                                                    <span
                                                                                                        className="font-digits text-success"
                                                                                                        style={{ color: "rgb(255, 255, 255)", fontSize: 22 }}
                                                                                                    >
                                                                                                        + 500
                                                                                                    </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        );

    }
}
export default App;
